.jobs__page .jobs_body {
    min-height: 35vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.jobs__page .jobs_body .page_title {
    text-align: center;
    margin: 50px 0 100px 0;
    font-size: 3.2rem;
    text-transform: capitalize;
    color: var(--primary-color);
}

.jobs__page .jobs_body .jobs_content {
    max-width: 50vw;

    padding: 25px 0 50px 0;
}

.jobs__page .jobs_body .jobs_content>p {
    color: var(--primary-color);
    text-align: center;

    margin-bottom: 15px;
}


@media only screen and (max-width: 768px) {

    .jobs__page .jobs_body .page_title {
        margin: 125px 0 50px 0;
    }

    .jobs__page .jobs_body .jobs_content {
        max-width: calc(100vw - 50px);

        padding: 25px 0 50px 0;
    }

}