/*  SECTION FIXED ELEMENTS  */

.fixed_elements {
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 100%;
  height: 100px;
  overflow: hidden;
  z-index: 900;
}

.fixed_elements .logo {
  position: fixed;
  top: 25px;
  right: 25px;
  width: 7.5%;
  opacity: 1;

  transition: all 500ms ease-in-out;
}

.fixed_elements .action_buttons {
  position: fixed;
  top: 25px;
  left: 25px;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 7.5px;
}

.fixed_elements .action_buttons .action_button {
  position: relative;
  width: 40px;
  height: 35px;
  padding-right: 0px;
  overflow: hidden;
  background-color: var(--primary-color-ultra-light);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  transition: all 400ms ease-in-out;
}

.fixed_elements .action_buttons .action_button>p {
  color: var(--primary-color);
  text-transform: lowercase;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 5px;

  overflow: hidden;
  /* Hide overflow content */
  opacity: 0;
  transition: opacity 0.1s ease-out;
  transition-delay: 0s;
  white-space: nowrap;
}

.fixed_elements .action_buttons .action_button .hamburger-react {
  position: inherit;
  left: -2.5px;
  overflow: hidden;
  min-width: 50px;
  padding: 0;
}

.fixed_elements .action_buttons .action_button svg {
  color: var(--primary-color);
}

.fixed_elements .action_buttons .action_button:hover {
  width: 135px;
}

.fixed_elements .action_buttons .action_button.question:hover {
  width: 200px;
}

.fixed_elements .action_buttons .action_button.back:hover {
  width: 250px;
}

.fixed_elements .action_buttons .action_button:hover p {
  width: auto;
  opacity: 1;
  transition: opacity 400ms ease-in 200ms;
  user-select: none;
}

.fixed_elements .action_buttons .action_button #question-mark-icon {
  color: var(--primary-color);
  font-size: 18px;
  min-width: 40px;
}

.fixed_elements .action_buttons .action_button #go-back-icon {
  color: var(--primary-color);
  font-size: 18px;
  min-width: 40px;
}

/*  !SECTION  */

/*  SECTION MENU  */

.popup_out_menu {
  position: fixed;
  top: 0;
  width: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 500;

  transition: width 750ms ease-in-out;
}

.popup_out_menu.active {
  width: 100vw;
}

.popup_out_menu .menu_wrapper {
  width: 0;
  height: 100%;
  background-color: white;
  padding: 0;

  transition: all 500ms ease-in-out;
}

.popup_out_menu.active .menu_wrapper {
  width: 75%;
}

.popup_out_menu .menu_wrapper .menu {
  padding-left: 200px;
  padding-top: 100px;
}

.popup_out_menu .menu_wrapper .menu .menu_item {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
  font-family: presti-display, serif;
  font-size: 2.8rem;
  font-weight: 500;
  padding: 15px 0;
  cursor: pointer;
  letter-spacing: 0.4rem;
  word-spacing: 0.4rem;

  opacity: 0;
  animation-fill-mode: forwards;
}

.popup_out_menu .menu_wrapper .menu .menu_item img {
  height: 3rem;
}

.popup_out_menu .menu_item:hover {
  background-size: 50% 50%;
  /* Adjust as needed */
  background-image: url('../assets/brush-stroke.png');
  /* Ensure path is correct */
  background-repeat: no-repeat;
  background-position: left center;
}

.popup_out_menu .menu_wrapper .socials {
  padding-left: 200px;
  padding-top: 50px;
  display: flex;
  gap: 20px;
}

.popup_out_menu {
  position: fixed;
  top: 0;
  width: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 500;

  transition: width 750ms ease-in-out;
}

.popup_out_menu.active {
  width: 100vw;
}

.popup_out_menu .menu_wrapper {
  width: 0;
  height: 100%;
  background-color: white;
  padding: 0;

  transition: all 500ms ease-in-out;
}

.popup_out_menu .menu_wrapper .logo--menu {
  position: absolute;
  right: calc(50px);
  top: 25px;
  width: 100px;
}

.popup_out_menu.active .menu_wrapper {
  position: relative;
  width: 75%;
}

.popup_out_menu.active .menu_wrapper .menu_section {
  height: 100vh;
  padding-bottom: 15px;
  overflow: scroll;
}

.popup_out_menu .menu_wrapper .menu {
  padding-left: 200px;
  padding-top: 100px;
  overflow: scroll;
}

.popup_out_menu .menu_wrapper .menu .menu_item {
  list-style: none;
  font-family: presti-display, serif;
  font-size: 2.8rem;
  font-weight: 500;
  padding: 15px 0;
  cursor: pointer;
  letter-spacing: 0.4rem;
  word-spacing: 0.4rem;

  opacity: 0;
  animation-fill-mode: forwards;
}

.popup_out_menu .menu_item:hover {
  background-size: 50% 50%;
  /* Adjust as needed */
  background-image: url('../assets/brush-stroke.png');
  /* Ensure path is correct */
  background-repeat: no-repeat;
  background-position: left center;
}

.popup_out_menu .menu_wrapper .socials {
  padding-left: 200px;
  padding-top: 50px;
  display: flex;
  gap: 35px;
  opacity: 0;
}

.popup_out_menu .menu_wrapper .socials svg {
  font-size: 40px;
  color: var(--secondairy-medium-gray);
}

.popup_out_menu .menu_wrapper .menu_buttons {
  padding-left: 200px;
  padding-top: 50px;
  display: flex;
  gap: 35px;
  opacity: 0;
}

.popup_out_menu .menu_wrapper .menu_buttons button {
  width: 40%;
  max-width: 220px;
  padding: 25px 15px;
  border-radius: 15px;
  background-color: transparent;
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border: solid 2px var(--primary-color-ultra-light);
  cursor: pointer;

  transition: all 300ms ease-in-out;
}

.popup_out_menu .menu_wrapper .menu_buttons button:hover {
  background-color: var(--primary-color-ultra-light);
}

.popup_out_menu .menu_wrapper .menu_buttons button.special {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.popup_out_menu .menu_wrapper .menu_buttons button.special:hover {
  background-color: var(--primary-color-ultra-light);
  color: var(--primary-color);
}

.popup_out_menu .menu_wrapper .language_selector {
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 12.5px;
  z-index: 900;

  color: var(--primary-color);
}

.popup_out_menu .menu_wrapper .language_selector>p {
  padding: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.popup_out_menu .menu_wrapper .language_selector>svg {
  margin-left: 5px;
  font-size: 1.3rem;
}

.popup_out_menu .menu_wrapper .language_selector>p.selected {
  background-color: var(--secondairy-light-gray);
  border-radius: 50%;
  color: black;
}

/*  !SECTION  */

@media only screen and (max-width: 768px) {
  .fixed_elements {
    width: 100%;
    height: 120px;
  }

  .fixed_elements .logo {
    position: fixed;
    top: 25px;
    right: 25px;
    width: 20%;
  }

  .fixed_elements .action_buttons .action_button {
    position: relative;
    width: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fixed_elements .action_buttons .action_button:hover {
    width: auto;
  }

  .fixed_elements .action_buttons .action_button.question:hover {
    width: auto;
  }

  .fixed_elements .action_buttons .action_button p {
    display: none;
    padding: 0;
  }

  .fixed_elements .action_buttons .action_button:hover p {
    display: none;
    padding: 0;
  }

  .fixed_elements .action_buttons .action_button .hamburger-react {
    left: 0;
    width: 35px;
  }

  .popup_out_menu.active {
    height: 101vh;
    width: 101vw;
  }

  .popup_out_menu.active .menu_wrapper {
    width: 100%;
    padding-bottom: 100px;
  }

  .popup_out_menu .menu_wrapper .logo--menu {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 100px;
  }

  .popup_out_menu .menu_wrapper .menu {
    padding-left: 0;
    padding-top: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup_out_menu .menu_wrapper .menu .menu_item {
    font-size: 1.9rem;
    padding: 10px 0;
  }

  .popup_out_menu .menu_item:hover {
    background-size: 0;
  }

  .popup_out_menu .menu_wrapper .socials {
    padding-left: 0;
    padding-top: 100px;
    display: flex;
    gap: 35px;
    opacity: 0;
    align-items: center;
    justify-content: center;
  }

  .popup_out_menu .menu_wrapper .socials svg {
    font-size: 25px;
    color: var(--secondairy-medium-gray);
  }

  .popup_out_menu .menu_wrapper .menu_buttons {
    padding-left: 0;
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup_out_menu .menu_wrapper .menu_buttons button {
    color: var(--primary-color);
    font-size: 0.8rem;
    padding: 10px;
    min-height: 70px;
  }

}