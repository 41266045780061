.invest__page .invest_body {
    min-height: 35vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
}

.invest__page .invest_body .page_title {
    text-align: center;
    margin: 50px 0 100px 0;
    font-size: 3.2rem;
    text-transform: capitalize;
    color: var(--primary-color);
}

.invest__page .invest_body .invest_content {
    max-width: 75vw;

    padding: 25px 0 50px 0;
}

.invest__page .invest_body .invest_content .opening_paragraph {
    padding: 0 15%;
    color: var(--primary-color);
    text-align: center;

    margin-bottom: 15px;
}

.invest__page .invest_body .invest_content .opening_paragraph .subtitle {
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 25px;
}

.invest__page .invest_body .invest_content .benefits_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin: 50px 0;
}

.invest__page .invest_body .invest_content .benefits_list .benefit_item {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary-color);
}

.invest__page .invest_body .invest_content .benefits_list .benefit_item .benefit_icon {
    font-size: 2.5rem;
    color: var(--primary-color);
}

.invest__page .invest_body .invest_content .benefits_list .benefit_item .benefit_title {
    font-weight: 500;
    margin-bottom: 10px;
}

.invest__page .invest_body .invest_content .benefits_list .benefit_item .benefit_text {
    text-align: center;
}

.invest__page .invest_body .invest_content .contact_form {
    display: block;
}


@media only screen and (max-width: 768px) {

    .invest__page .invest_body .page_title {
        margin: 125px 0 50px 0;
    }

    .invest__page .invest_body .invest_content {
        max-width: calc(100vw - 50px);

        padding: 25px 0 50px 0;
    }

    .invest__page .invest_body .invest_content .opening_paragraph {
        padding: 0;
    }

    .invest__page .invest_body .invest_content .benefits_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        margin: 50px 0;
        font-size: 0.85rem;
    }


    .invest__page .invest_body .invest_content .benefits_list .benefit_item .benefit_icon {
        font-size: 2rem;
    }

}