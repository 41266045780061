#contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: 100%;
}

#contact-form .checkmark_container {
    display: flex;
    gap: 15px;
    cursor: pointer;
}

#contact-form .checkmark_container .checkmark_box {
    border: var(--primary-color-ultra-light) 1px solid;
    border-radius: 2.5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#contact-form .checkmark_container .checkmark_box.selected {
    background-color: var(--primary-color-ultra-light);
}

#contact-form .checkmark_container .checkmark_box svg {
    color: white;
    font-size: 1.4rem;
}

#contact-form .checkmark_container .checkmark_box.selected svg {
    color: white;
}

#contact-form .checkmark_container .checkmark_text {
    width: 90%;
    font-size: 0.75rem;
    color: var(--secondairy-medium-gray);
}


