.footer {
  position: relative;
  width: 100%;
  height: 65vh;
  background-color: var(--primary-color);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 60px;
}

.footer .footer_logo {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 17.5%;
  max-height: 35%;
}

.footer .contact__details {
  display: flex;
  width: 90%;
  max-height: 50%;
  gap: 7.5%;
  margin-top: 5%;
}

.footer .contact__details .contact__detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 100%;
  border-radius: 15px;
  border: 1px solid var(--primary-color-light);
  padding: 20px;
  cursor: pointer;
  color: white;

  transition: all 350ms ease-in-out;
}

.footer .contact__details .contact__detail:hover {
  background-color: var(--primary-color-ultra-light);
  color: black;
}

.footer .contact__details .contact__detail:hover .button {
  border: 1px solid var(--primary-black);
}

.footer .contact__details .contact__detail svg {
  font-size: 2rem;
  margin-bottom: 15px;
}

.footer .contact__details .contact__detail .mini_title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.footer .contact__details .contact__detail p {
  margin-bottom: 15px;
  text-align: center;
}

.footer .contact__details .contact__detail .button {
  width: 80%;
  height: 30%;
  background-color: var(--primary-color-ultra-light);
  border: 1px solid var(--primary-color-ultra-light);
  color: var(--primary-black);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  font-family: "novecento-sans-wide", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.85rem;
  border-radius: 25px;
  align-self: center;
}

.footer .disclaimer {
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 20px;
  cursor: pointer;
  color: white;
}

@media only screen and (max-width: 768px) {

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    height: auto;
    padding-bottom: 60px;
  }

  .footer .footer_logo {
    position: relative;
    max-height: 100%;
    width: 25%;
    bottom: 0;
    transform: none;
    left: 0;
  }

  .footer .contact__details {
    width: 90%;
    max-height: 100%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer .contact__details .contact__detail {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 15px;
    font-size: 0.8rem;
    height: 110px;
    width: 100%;
  }

  .footer .contact__details .contact__detail svg {
    justify-self: center;
    grid-column: span 1;
    grid-row: span 2;
    margin: 0;
  }

  .footer .contact__details .contact__detail .mini_title {
    display: none;
  }

  .footer .contact__details .contact__detail p {
    color: white;
    justify-self: center;
    grid-column: span 1;
    margin: 0;
  }

  .footer .contact__details .contact__detail .button {
    width: 80%;
    justify-self: center;
    grid-column: span 1;
  }

  .footer .contact__details .contact__detail:hover {
    background-color: transparent;
    color: white;
  }
}