.home_page {
  position: relative;
  width: 100vw;
  overflow: hidden;
}

.home_page section {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

/*  SECTION WELCOME  */

.home_page .welcome_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  background-color: var(--primary-color);
  opacity: 0;
  visibility: hidden;
  transition: all 1000ms ease-in;
}

.home_page .welcome_page.active {
  visibility: visible;
  opacity: 1;
}

.home_page .welcome_page .logo {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 25%;
  transform: translate(-50%, -50%);
}

.home_page .welcome_page .slogan.sub {
  position: absolute;
  top: calc(30vw);
  left: 50%;
  transform: translateX(-50%);
  color: white;
  display: flex;
  gap: 10px;
  font-size: 4vh;

  font-family: "novecento-sans-wide", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0;

  animation: fadeIn 250ms forwards 2700ms;
}

.home_page .welcome_page .slogan {
  position: absolute;
  top: calc(25vw);
  left: 50%;
  transform: translateX(-50%);
  color: white;
  display: flex;
  gap: 10px;
  font-size: 4vh;
}

.home_page .welcome_page .word {
  display: flex;
  letter-spacing: 0.5rem;
}

.home_page .welcome_page .letter {
  font-family: "novecento-sans-wide", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.home_page .welcome_page .letter.active {
  opacity: 1;
}

@media only screen and (max-width: 768px) {

  .home_page .welcome_page {
    width: 101vw;
    height: 101vh;
  }

}

/*  !SECTION  */

/*  SECTION HOME  */

.home_page #home {
  overflow: hidden;
  height: 100vh;
}

.home_page #home .content_button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  left: 50%;
  bottom: 115px;
  transform: translate(-50%, 0);
  color: white;
  background-color: rgb(170, 188, 192, 0.8);
  /* border-color: white; */
  height: 75px;
  width: 300px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 20px;
}

.home_page #home .content_button svg {
  font-size: 2rem;
}

.home_page #home .content_button:hover {
  color: white;
  background-color: rgb(170, 188, 192, 1);
  /* border-color: white; */
}

.home_page #home .cover_photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home_page #home .logo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25%;
  transform: translate(-50%, -50%);
}

.home_page #home .quote {
  position: absolute;
  left: 50%;
  top: 47.5%;
  width: 60%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 50px 20px;
  background-color: rgba(255, 255, 255, 0.850);
  border-radius: 5px;
  font-size: 1.4rem;

  opacity: 0;
  transition: opacity 1750ms ease-in-out, top 1000ms ease-in-out;
}

.home_page #home .quote.active {
  opacity: 1;
  top: 45%;
}

.home_page #home .quote>p {
  text-align: center;
  font-weight: 400;
}

.home_page #home .quote>p:first-child {
  text-align: center;
  font-style: italic;
}

/*  !SECTION  */

/*  SECTION ABOUT  */

.home_page #about {
  position: relative;
  padding: 100px 0;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {

  .home_page #about {
    height: auto;
  }

  .home_page #about .section_content {
    flex-direction: column;
    padding: 0;
  }

  .home_page #about .section_content .section_title {
    padding: 0 25px;
    margin: 0;
    width: auto;
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  .home_page #about .section_content .section_title__word {
    width: 100%;
  }

  .home_page #about .section_content .section_sub_title {
    padding: 50px 25px;
    margin: 0;
    width: 100%;
    font-size: 1.7rem;
    line-height: 2.2rem;
  }

  .home_page #about .section_content .section_text {
    margin: 0;
    padding: 25px;
    font-size: 1rem;
  }
}


/*  !SECTION  */

/*  SECTION ANIMATIONS  */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*  !SECTION  */

.home_page {
  position: relative;
  width: 100vw;
  overflow: hidden;
}

.home_page section {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

/*  SECTION WELCOME  */

.home_page .welcome_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  background-color: var(--primary-color);
  opacity: 0;
  visibility: hidden;
  transition: all 1000ms ease-in-out;
}

.home_page .welcome_page.active {
  visibility: visible;
  opacity: 1;
}

.home_page .welcome_page .logo {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 25%;
  transform: translate(-50%, -50%);
}

.home_page .welcome_page .slogan {
  position: absolute;
  top: calc(25vw);
  left: 50%;
  transform: translateX(-50%);
  color: white;
  display: flex;
  gap: 10px;
  font-size: 4vh;
}

.home_page .welcome_page .word {
  display: flex;
  letter-spacing: 0.5rem;
}

.home_page .welcome_page .letter {
  font-family: "novecento-sans-wide", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.home_page .welcome_page .letter.active {
  opacity: 1;
}

@media (max-width: 768px) {

  .home_page .welcome_page .logo {
    width: 70%;
  }


  .home_page .welcome_page .slogan {
    top: 50%;
    left: 50%;
    font-size: 2vh;
  }

  .home_page .welcome_page .word {
    letter-spacing: 0.2rem;
  }

  .home_page .welcome_page .slogan.sub {
    top: calc(50% + 4vh);
    left: 50%;
    font-size: 2vh;
  }

  .home_page #home .content_button {
    bottom: 150px;
    height: 75px;
    width: 60vw;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 20px;
  }

  .home_page #home .content_button svg {
    font-size: 2rem;
  }

  .home_page #home .content_button:hover {
    color: white;
    background-color: rgb(170, 188, 192, 0.8);
    /* border-color: white; */
  }

  .home_page #home .content_button:target {
    outline: none;
  }

}

/*  !SECTION  */

/*  SECTION HOME  */

.home_page #home {
  overflow: hidden;
}

.home_page #home .cover_photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home_page #home .logo {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 25%;
  transform: translate(-50%, -50%);
}

.home_page #home .quote {
  position: absolute;
  left: 50%;
  top: 47.5%;
  width: 60%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 50px 20px;
  background-color: rgba(255, 255, 255, 0.850);
  border-radius: 5px;
  font-size: 1.4rem;

  opacity: 0;
  transition: opacity 1750ms ease-in-out, top 1000ms ease-in-out;
}

.home_page #home .quote.active {
  opacity: 1;
  top: 45%;
}

.home_page #home .quote>p {
  text-align: center;
  font-weight: 400;
}

.home_page #home .quote>p:first-child {
  text-align: center;
  font-style: italic;
}

@media (max-width: 768px) {

  .home_page #home .cover_photo {
    width: 100%;
    height: 100%;
  }

  .home_page #home .logo {
    width: 70%;
  }

  .home_page #home .quote {
    width: 80%;
    font-size: 1.3rem;
  }

}

/*  !SECTION  */

/*  SECTION ABOUT  */

.home_page #about {
  position: relative;
  padding: 100px 0;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
}

/*  !SECTION  */

/*  SECTION FEATURED PROJECT */

#featured-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#featured-project .section_title {
  width: calc(100% - 100px);
  margin: 0;
}

#featured-project .section_image {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

}

#featured-project .section_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;

  transition: all 400ms ease-in-out;
}

#featured-project .section_image img:hover {
  height: 110%;
  width: 110%;
}

#featured-project .section_content {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-left: 150px;
}

#featured-project .status_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  background-color: transparent;
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  overflow: hidden;
}

#featured-project .status_overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 150px 0 0;
  border-color: var(--primary-color) transparent transparent transparent;
  z-index: 1;
}

#featured-project .status_overlay::after {
  content: attr(data-status);
  position: absolute;
  top: 25%;
  left: 35%;
  transform: rotate(-45deg) translateX(-50%) translateY(-50%);
  transform-origin: 0 0;
  white-space: nowrap;
  z-index: 2;
}

@media only screen and (max-width: 768px) {

  #featured-project {
    display: grid;
    grid-template-columns: 1fr;
  }

  #featured-project .section_content {
    padding: 35px 25px;
    margin: 0;
  }

  #featured-project .section_title {
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  #featured-project .section_title__word {
    width: 100%;
  }

  #featured-project .section_sub_content {
    padding: 0;
  }

  #featured-project .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
  }
}

/* !SECTION  */

/*  SECTION ANIMATIONS  */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*  !SECTION  */

/*  SECTION PROJECTS  */

#projects {
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: var(--primary-color);
  padding: 5% 0;
  height: auto;
  min-height: 100vh;
}

.projects_filter_menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 20px;
  width: 90%;
  align-items: center;
  padding-bottom: 25px;
}

.projects_filter_menu > div {
  color: var(--primary-color-ultra-light);
  opacity: 0.5;
  text-transform: uppercase;
  padding: 7.5px 0;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.projects_filter_menu > div::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 1px; /* Adjust the height of the border */
  background-color: white;
  transition: width 0.5s ease, left 0.5s ease;
}

.projects_filter_menu > div:hover:not(.selected)::before {
  width: 100%;
  left: 0;
}

.projects_filter_menu > div.selected {
  color: white;
  font-weight: 500;
  border-bottom: white 1px solid;
  opacity: 1;
  /* Ensure the static border is visible */
  position: relative;
}

.projects_filter_menu > div.selected::before {
  display: none; /* Disable the hover effect for the selected item */
}

.projects_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 33vh;
  height: 100%;
  width: 90%;
  gap: 5vw;
}

.projects_grid .project_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.projects_grid .project_wrapper .project_status {
  position: absolute;
  left: -100%;
  bottom: 20%;
  width: 100%;
  height: 15%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  transition: all 600ms ease-in-out;
}

.projects_grid .project_wrapper .project_status.hovered {
  left: 0%;
}

.projects_grid .project_wrapper .project_info {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 7.5px 0 0 0;
  height: 20%;
}

.projects_grid .project_wrapper .project_info .project_info_text {
  width: 60%;
}

.projects_grid .project_wrapper .project_info .content_button {
  width: 40%;
  color: var(--primary-color-ultra-light);
  font-weight: 600;
  height: 100%;
}

.projects_grid .project_wrapper .project_info .content_button:hover {
  color: white;
}

.projects_grid .project_wrapper .project_info h3 {
  font-family: presti-display, serif;
  font-weight: 500;
  padding: 0 0 2.5px 0;
}

.projects_grid .project_wrapper .project_info p {
  display: flex;
  align-items: center;
  font-weight: 300;
  color: var(--primary-color-ultra-light);
}

.projects_grid .project_wrapper .project_info p svg {
  color: var(--primary-color-ultra-light);
  margin-right: 5px;
}

.projects_grid .project_wrapper img {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  #projects {
    background-color: var(--primary-color);
    padding: 10% 0;
    height: auto;
  }

  .projects_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 40vh;
  }

  .projects_grid .project_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
  }

  .projects_grid .project_wrapper .project_status {
    position: absolute;
    left: 0%;
    bottom: 30%;
    height: 7.5%;
    background-color: white;
    font-weight: 700;
    font-size: 0.8rem;
    opacity: 0.85;
  }

  .projects_grid .project_wrapper .project_info {
    color: white;
    display: flex;
    flex-direction: column;
    height: 30%;
    gap: 5px;
  }

  .projects_grid .project_wrapper .project_info .project_info_text {
    width: 100%;
  }

  .projects_grid .project_wrapper .project_info .content_button {
    width: 100%;
    color: var(--primary-color-ultra-light);
    font-weight: 600;
    flex-grow: 1;
    padding: 2.5px 15px;
    border-radius: 10px;
  }

  .projects_grid .project_wrapper .project_info h3 {
    font-family: presti-display, serif;
    font-weight: 500;
    padding: 0 0 2.5px 0;
  }

  .projects_grid .project_wrapper .project_info p {
    display: flex;
    align-items: center;
    font-weight: 300;
    color: var(--primary-color-ultra-light);
    font-size: 0.85rem;
  }

  .projects_grid .project_wrapper .project_info p svg {
    color: var(--primary-color-ultra-light);
    margin-right: 5px;
  }

  .projects_grid .project_wrapper img {
    height: 70%;
  }
}


/*  !SECTION  */

/*  SECTION FEATURED NEWS  */

#featured-news {
  position: relative;
  width: 100%;
  max-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#featured-news .section_content {
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: center;
  margin-left: 150px;
  height: 100vh;
  width: calc(100% - 150px);
}

#featured-news .section_title {
  width: calc(100% - 150px);
  max-width: 350px;
  margin: 0;
}

#featured-news .section_sub_content {
  font-style: italic;
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-weight: 600;
  margin: 25px 0;
}

#featured-news .buttons {
  margin-top: 25px;
}

#featured-news .section_image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}

#featured-news .section_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;

  transition: all 400ms ease-in-out;
}

#featured-news .section_image img:hover {
  height: 110%;
  width: 110%;
}

@media only screen and (max-width: 768px) {

  .home_page {
    width: calc(100vw + 1%);
  }

  #featured-news {
    position: relative;
    width: 100%;
    max-height: auto;
    display: flex;
    flex-direction: column;
  }

  #featured-news .section_content {
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-content: center;
    margin: 0;
    padding: 40px 25px;
    height: auto;
    width: 100%;
  }

  #featured-news .section_title {
    width: 100%;
    max-width: 350px;
    margin: 0;
    padding: 0;
  }

  #featured-news .section_title__word {
    width: 100%;
    max-width: 350px;
    margin: 0;
  }

  #featured-news .section_sub_content {
    font-style: italic;
    font-size: 1.5rem;
    line-height: 2.2rem;
    font-weight: 600;
    margin: 25px 0;
  }

  #featured-news .buttons {
    margin-top: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


/*  !SECTION  */